import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration TEST ENVIRONMENT
const firebaseConfig = {
  apiKey: 'AIzaSyAKPPXxJ1i-X4qK-b-QmeAWYVBkpZ3LpvA',
  authDomain: 'vodium-test.firebaseapp.com',
  projectId: 'vodium-test',
  storageBucket: 'vodium-test.appspot.com',
  messagingSenderId: '526495927231',
  appId: '1:526495927231:web:1e3e684f05d01c5a76ef0a',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const VODIUM_SIGN_IN = "https://vodium.com/sign-in-test?hs_preview=LpJftdon-133341231627";
export const VODIUM_AUTH_WEB_TOKEN = "VODIUM_AUTH_WEB_TOKEN";
export const VODIUM_WEB_APP = 'https://test-lite.vodium.com/';


/* eslint-disable no-extend-native */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import './custonSVGForToolbar';
import PlaceHolder from './placeholder';
import { calculateScrollSpeed } from '../utils/scrollSpeedCalculation';
import { setAdvanceScrollAction } from '../redux/actions/advanceScrollActions';
import { setPrompterTextAction } from '../redux/actions/prompterTextActions';
import { setIsEditModeAction } from '../redux/actions/editModeActions';
import { setScrollDistanceAction } from '../redux/actions/scrollDistanceActions';
import { setSettingsOpenAction } from '../redux/actions/settingsOpenActions';
import Modal from './searchModal';
import { setPrompterOnAction } from '../redux/actions/prompterOnActions';
import { Tooltip } from 'antd';
import {
  TextAreaContainer,
  StyledReactQuill,
} from '../styledComponent/scriptWindowStyleComponent';

//CSS
const video = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "68%",
  maxWidth: "860px",
  display: 'none'
}

String.prototype.insert = function (index, string) {
  if (index > 0) {
    return this.substring(0, index) + string + this.substr(index);
  }

  return string + this;
};
const ScriptWindows = ({ fileText, findText, setFindText }) => {
  let reactQuillRef = useRef(null);
  const [showPlaceholer, setPlaceHolder] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const prompterText = useSelector((state) => state.prompterText);
  const prompterOn = useSelector((state) => state.prompterOn);
  const scrollDistanceState = useSelector((state) => state.scrollDistance);
  const advanceScroll = useSelector((state) => state.advanceScroll);
  const scrollSpeed = useSelector((state) => state.scrollSpeed);
  const prompterFontSize = useSelector((state) => state.prompterFontSize);
  const [editorValue, setIsEditorValue] = useState(prompterText);
  const ScriptFileText = useSelector((state) => state.fileText);
  const darkMode = useSelector((state) => state.darkMode);
  const inTeams = useSelector((state) => state.inTeams);
  const inMobile = useSelector((state) => state.inMobile);
  const settingsOpen = useSelector((state) => state.settingsOpen);
  const isEditMode = useSelector((state) => state.isEditMode);
  const [isTextEdited, setTextIsEdited] = useState(ScriptFileText);
  const dispatch = useDispatch();
  let scrollDistance = 0;
  let newScrollSpeed = calculateScrollSpeed(scrollSpeed);
  // Use useRef for mutable variables that we want to persist without triggering a re-render on their change
  const requestFrame = React.useRef();
  const animate = () => {
    // const textDisplay = document.getElementById('text-display');
    // const textAreaContainer = document.getElementById('text-area-container');
    const editor = document.getElementsByClassName('ql-editor')[0];

    // Only if text display is visible
    if (prompterOn) {
      let delta = editor.scrollHeight - editor.scrollTop;
      let shouldStopScroll = delta <= 220;
      if (!shouldStopScroll) {
        scrollDistance += newScrollSpeed;

        // Sets the scroll distance globally
        dispatch(setScrollDistanceAction(scrollDistanceState + scrollDistance));
        if (requestFrame.current) {
          requestFrame.current = requestAnimationFrame(animate);
        }
      }
    }
  };
  useEffect(() => {
    if (prompterOn) {
      const editor = document.getElementsByClassName('ql-editor')[0];
      editor.scroll({
        top: scrollDistanceState,
        left: 0,
        behavior: 'auto',
      });
    }
  }, [scrollDistanceState]);
  useEffect(() => {
    // if (modalOpen) {
    console.log('{textarea} prompterOnUseEffct', prompterOn);
    const editor = document.getElementsByClassName('ql-editor')[0];

    if (prompterOn) {
      console.log('{textarea} prompterOnUseEffct IN If');
      newScrollSpeed = calculateScrollSpeed(scrollSpeed);
      requestFrame.current = requestAnimationFrame(animate);

      editor.onmousewheel = (e) => {
        e.preventDefault();
      }; //prevent from scroll using mouse wheel
      editor.classList.add('overlay'); //this is to disallow user to scroll by dragging scrollbar

      unSelectTextIfSelected();
    } else {
      editor.onmousewheel = () => {};
      editor.classList.remove('overlay');

      console.log('{textarea} prompterOnUseEffct IN Else');
      requestFrame.current = null;
      cancelAnimationFrame(requestFrame.current);
    }
    if (settingsOpen) {
      // dispatch(setPrompterOnAction(false));
      dispatch(setSettingsOpenAction(!settingsOpen));
    }
    // }
  }, [prompterOn]);
  const unSelectTextIfSelected = () => {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  };
  useEffect(() => {
    setIsEditorValue(fileText);
  }, [fileText]);
  useEffect(() => {
    if (findText) {
      setModalOpen(findText);
    }
  }, [findText]);
  useEffect(() => {
    if (!modalOpen) {
      const toolbar = document.getElementsByClassName('ql-toolbar')[0];
      const editor = document.getElementsByClassName('ql-editor')[0];
      toolbar.classList.add('control-item');
      editor.addEventListener('focus', () => {
        dispatch(setIsEditModeAction(true));
      });
      console.log('ISEDITMODE???', isEditMode);
      editor.addEventListener('blur', () => {
        dispatch(setIsEditModeAction(false));
      });
      if (!prompterOn) {
        toolbar.classList.remove('hidden');
        // const screenWith = window.innerWidth;
        // const toolbarWidth = toolbar.offsetWidth;
        // const leftPosition = screenWith - toolbarWidth - 59;
        // toolbar.style.left = leftPosition + 'px';
        // toolbar.style.top = '112%';
        // toolbar.style.webkitAppRegion = 'no-drag';
      } else {
        toolbar.classList.add('control-item', 'hidden');
        dispatch(setIsEditModeAction(false));
      }
      // const screenWith = window.innerWidth;
      // const toolbarWidth = toolbar.offsetWidth;
      // const leftPosition = screenWith - toolbarWidth - 17;
      // const userAgent = navigator.userAgent.toLowerCase();
      // if (userAgent.includes('firefox')) {

      // } else if (userAgent.includes('chrome')) {
      //   toolbar.style.top = '45%';
      //   toolbar.style.bottom = '50%';
      // } else if (userAgent.includes('safari')) {

      // } else if (userAgent.includes('edge')) {

      // } else if (userAgent.includes('opera')) {

      // } else {

      // }
      // if (isMacOs) {
      //   toolbar.style.left = leftPosition + 'px';
      //   toolbar.style.justifyContent = 'flex-end';
      //   toolbar.style.top = '48%';
      //   toolbar.style.bottom = '48%';
      // } else if (process.platform === 'win32') {
      //   toolbar.style.left = leftPosition + 'px';
      //   toolbar.style.justifyContent = 'flex-end';
      //   toolbar.style.top = '45%';
      //   toolbar.style.bottom = '50%';
      //   toolbar.style.webkitAppRegion = 'no-drag';
      // }

      editor.addEventListener('copy', (e) => {
        e.preventDefault();
        let divElement = document.createElement('div');
        divElement.append(window.getSelection().getRangeAt(0).cloneContents());
        const unprivilegedEditor = reactQuillRef.current.makeUnprivilegedEditor(
          reactQuillRef.current.getEditor()
        );
        const selection = unprivilegedEditor.getSelection();
        var selectedContent = unprivilegedEditor.getContents(
          selection.index,
          selection.length
        );
        var tempContainer = document.createElement('div');
        var tempQuill = new Quill(tempContainer);
        tempQuill.setContents(selectedContent);

        e.clipboardData.setData(
          'text/plain',
          tempContainer.querySelector('.ql-editor').innerText
        );
        e.clipboardData.setData(
          'text/html',
          tempContainer.querySelector('.ql-editor').innerHTML
        );
      });

      editor.addEventListener('cut', (e) => {
        e.preventDefault();
        let divElement = document.createElement('div');
        divElement.append(window.getSelection().getRangeAt(0).cloneContents());
        const unprivilegedEditor = reactQuillRef.current.makeUnprivilegedEditor(
          reactQuillRef.current.getEditor()
        );
        const selection = unprivilegedEditor.getSelection();
        if (selection.length === 0) {
          return;
        }
        var selectedContent = unprivilegedEditor.getContents(
          selection.index,
          selection.length
        );
        if (selectedContent.length === 0) {
          return;
        }
        var tempContainer = document.createElement('div');
        var tempQuill = new Quill(tempContainer);
        tempQuill.setContents(selectedContent);

        e.clipboardData.setData(
          'text/plain',
          tempContainer.querySelector('.ql-editor').innerText
        );
        e.clipboardData.setData(
          'text/html',
          tempContainer.querySelector('.ql-editor').innerHTML
        );

        window.getSelection().deleteFromDocument();
      });

      editor.addEventListener('paste', (event) => {
        event.preventDefault();
        let paste =
          event.clipboardData.getData('text/html') ||
          event.clipboardData.getData('text');
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
        selection.deleteFromDocument();
        paste = paste.replace(/<img[^>]*>/g, '');
        let googleDocRegex = /<b.* id="docs-internal-guid-\S*">/i;
        if (paste.match(googleDocRegex)) {
          paste = paste.replace(googleDocRegex, '');
          paste = paste.replace('</b>', '');
        }

        // var htmlObject = document.createElement('div');
        var htmlObject = new DOMParser().parseFromString(paste, 'text/html');

        //some rich text editors have style tag instead of inline style e.g macOS notes
        let styleTag = htmlObject.getElementsByTagName('style');
        let classesWithStyle = {};
        try {
          if (styleTag.length) {
            let classes = styleTag[0].innerHTML.split('\n');
            for (let i = 1; i < classes.length; i++) {
              let splitetdValue = classes[i].split('{');
              if (splitetdValue[0].split('.')[1]) {
                classesWithStyle[splitetdValue[0].split('.')[1].trim()] =
                  splitetdValue[1].replace('}', '');
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
        let allSpans = htmlObject.querySelectorAll('span, li, p');
        for (let i = 0; i < allSpans.length; i++) {
          if (
            (allSpans[i].style.color === 'rgb(0, 0, 0)' ||
              allSpans[i].style.color === 'black' ||
              allSpans[i].style.color === '#000000') &&
            (allSpans[i].style.backgroundColor === 'transparent' ||
              !allSpans[i].style.backgroundColor)
          ) {
            allSpans[i].style.color = 'unset';
          }
          if (allSpans[i].style.textDecoration === 'underline') {
            allSpans[i].innerHTML = '<u>' + allSpans[i].innerHTML + '</u>';
          }
          if (styleTag.length) {
            let cssText = '';
            let classList = allSpans[i].classList;
            for (let j = 0; j < classList.length; j++) {
              if (classesWithStyle[classList[j]]) {
                cssText += classesWithStyle[classList[j]];
              }
            }
            allSpans[i].style.cssText =
              allSpans[i].style.cssText + ';' + cssText;

            if (cssText.includes('underline')) {
              allSpans[i].innerHTML = '<u>' + allSpans[i].innerHTML + '</u>';
            }

            if (allSpans[i].tagName === 'LI' && allSpans[i].style) {
              let haveSpan = allSpans[i].getElementsByTagName('span');
              let cText = allSpans[i].style.cssText;
              if (
                (allSpans[i].style.color === 'rgb(0, 0, 0)' ||
                  allSpans[i].style.color === 'black' ||
                  allSpans[i].style.color === '#000000') &&
                (allSpans[i].style.backgroundColor === 'transparent' ||
                  !allSpans[i].style.backgroundColor)
              ) {
                cText = cText.replace('color: rgb(0, 0, 0)', '');
                cText = cText.replace('color: #000000', '');
              }
              if (haveSpan.length) {
                console.log(cText);
                haveSpan[0].style.cssText += cText;
                if (haveSpan[1]) {
                  haveSpan[1].style.cssText += cText;
                }
              } else {
                allSpans[i].innerHTML =
                  "<span style='" +
                  cText +
                  "'>" +
                  allSpans[i].innerHTML +
                  '</span>';
              }
            }
          }
        }

        let allP = htmlObject.querySelectorAll('p');
        for (let i = 0; i < allP.length; i++) {
          if (allP[i].innerHTML != '&nbsp;' && allP[i].style) {
            let cText = allP[i].style.cssText;
            if (
              (allP[i].style.color === 'rgb(0, 0, 0)' ||
                allP[i].style.color === 'black' ||
                allP[i].style.color === '#000000') &&
              (allP[i].style.backgroundColor === 'transparent' ||
                !allP[i].style.backgroundColor)
            ) {
              cText = cText.replace('color: rgb(0, 0, 0)', '');
              cText = cText.replace('color: #000000', '');
            }
            allP[i].innerHTML =
              "<span style='" + cText + "'>" + allP[i].innerHTML + '</span>';
          }
        }

        console.log(paste, htmlObject);
        let frag = selection
          .getRangeAt(0)
          .createContextualFragment(htmlObject.documentElement.innerHTML);

        selection.getRangeAt(0).insertNode(frag);
      });

      toolbar.addEventListener('mousedown', (e) => {
        e.preventDefault();
      });

      editor.onscroll = (e) => {
        dispatch(setScrollDistanceAction(e.target.scrollTop));
      };

      // return () => {
      //   editor.removeEventListener('copy');
      //   editor.removeEventListener('cut');
      //   editor.removeEventListener('focus');
      //   editor.removeEventListener('blur');
      //   editor.removeEventListener('paste');
      //   editor.onscroll = () => {};
      // };
    }
  }, [dispatch, modalOpen, prompterOn, isEditMode]);
  useEffect(() => {
    let editor = document.getElementsByClassName('ql-editor')[0];
    editor.style.fontSize = `${prompterFontSize}px`;
    editor.style.lineHeight = `${prompterFontSize + 7}px`;
  }, [prompterFontSize]);

  const PageUpDown = (distance) => {
    const textBox = document.getElementsByClassName('ql-editor')[0];
    const adjust = distance === 0 ? textBox.scrollTop : 0;
    if (!prompterOn) {
      textBox.scroll({
        top: textBox.scrollTop + distance - adjust,
        left: 0,
        behavior: 'smooth',
      });
    }

    dispatch(setScrollDistanceAction(distance));
    dispatch(setAdvanceScrollAction(null));
  };
  switch (advanceScroll) {
    case 'forward':
      console.log('{textarea} Scroll Switch', advanceScroll);

      PageUpDown(200);
      break;
    case 'back':
      PageUpDown(-200);
      break;
    case 'top':
      PageUpDown(0);
      break;
    default:
      break;
  }

  const handleKeyDown = async (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
      console.log('CONTROL F OR COMMAND F WAS CLICK');
      event.preventDefault();
      setModalOpen(true);
      dispatch(setPrompterOnAction(false));
      // dispatch(setSettingsOpenAction(!settingsOpen));
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setFindText(false);
  };

  const handleSearch = (searchValue) => {
    try {
      if (reactQuillRef.current) {
        const editor = reactQuillRef.current.getEditor();
        const text = editor.getText().toLowerCase();
        const searchValueLower = searchValue.toLowerCase();
        const index = text.indexOf(searchValueLower);
        if (index !== -1) {
          editor.setSelection(index, searchValueLower.length);
          editor.formatText(
            index,
            searchValueLower.length,
            'background',
            'none'
          );
          console.log(`handle search matches found for "${searchValue}"`);
          return true;
        } else {
          console.log(`No matches found for "${searchValue}"`);
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleNext = (searchValue) => {
    try {
      if (reactQuillRef.current) {
        const editor = reactQuillRef.current.getEditor();
        const text = editor.getText().toLowerCase();
        const searchValueLower = searchValue.toLowerCase();
        const index = editor.getSelection().index + 1;
        const nextIndex = text.indexOf(searchValueLower, index);
        if (nextIndex !== -1) {
          editor.setSelection(nextIndex, searchValue.length);
          editor.formatText(
            nextIndex,
            searchValue.length,
            'background',
            'none'
          );
          console.log(`handle next matches found for "${searchValue}"`);
          return true;
        } else {
          console.log(`No next matches found for "${searchValue}"`);
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrev = (searchValue) => {
    try {
      if (reactQuillRef.current) {
        const editor = reactQuillRef.current.getEditor();
        const text = editor.getText().toLowerCase();
        let index = editor.getSelection().index - searchValue.length - 1;
        index = text.lastIndexOf(searchValue.toLowerCase(), index);
        if (index !== -1) {
          editor.setSelection(index, searchValue.length);
          editor.formatText(index, searchValue.length, 'background', 'none');
          console.log(`handle prev matches found for "${searchValue}"`);
          return true;
        } else {
          console.log(`No previous matches found for "${searchValue}"`);
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.add('lightMode');
    }

    return () => {
      document.body.classList.remove('darkMode');
      document.body.classList.remove('lightMode');
    };
  }, [darkMode]);
  // useEffect(()=>{
  //   const toolbar = document.getElementsByClassName('ql-toolbar')[0];
  //   if (isEditMode) {
  //     toolbar.classList.add('readonly-mode');
  //   } else {
  //     toolbar.classList.remove('readonly-mode');
  //   }
  // })
  const handleQuillChange = (value) => {
    setIsEditorValue(value);
    dispatch(setPrompterTextAction(value));
    setPlaceHolder(value.trim() === '' || value === '<p><br></p>');
  };
  useEffect(() => {
    setPlaceHolder(
      prompterText.trim() === '' || prompterText === '<p><br></p>'
    );
  }, [prompterText, showPlaceholer]);
  useEffect(() => {
    setIsEditorValue(prompterText);
    setTextIsEdited(ScriptFileText);
  }, [prompterText, ScriptFileText]);
  const textAreaClick = () => {
    console.log('click');
    dispatch(setIsEditModeAction(true));
    console.log('isedit', isEditMode);
  };
  const textAreaBlur = () => {
    console.log('click')
    dispatch(setIsEditModeAction(false));
    console.log('isedit', isEditMode)
  };
  // const [showTooltip, setShowTooltip] = useState(false);
  //  const [hoveredButton, setHoveredButton] = useState(null);
  // useEffect(() => {
  //   if (reactQuillRef.current) {
  //     const quillToolbar =
  //       reactQuillRef.current.getEditor().container.previousSibling;
  //     quillToolbar.addEventListener('mouseover', handleToolbarHover);
  //     quillToolbar.addEventListener('mouseout', handleToolbarHoverOut);

  //     return () => {
  //       quillToolbar.removeEventListener('mouseover', handleToolbarHover);
  //       quillToolbar.removeEventListener('mouseout', handleToolbarHoverOut);
  //     };
  //   }
  // }, [showTooltip, reactQuillRef]);

  // const handleToolbarHoverOut = (event) => {
  //   // Show the Tooltip
  //    setHoveredButton(null);
  //   setShowTooltip(true);
  // };

  // const handleToolbarHover = (event) => {
  //   // Hide the Tooltip
  //      const buttonClass = event.target.getAttribute('class');
  //      if (buttonClass && buttonClass.startsWith('ql-')) {
  //        // Show the Tooltip only when hovering over a toolbar button
  //        setHoveredButton(buttonClass);
  //        setShowTooltip(true);
  //      } else {
  //        // Hide the Tooltip if not hovering over a toolbar button
  //        setHoveredButton(null);
  //        setShowTooltip(false);
  //      }

  // };

  // Initialize WebSpellChecker
  useEffect(() => {
    const editorElement = document.getElementsByClassName('ql-editor')[0];

    if (editorElement && window.WEBSPELLCHECKER) {
      window.WEBSPELLCHECKER.init({
        container: editorElement,
      });
    }
  }, []);


  // useEffect(()=> {
  //   const toolbarHolder = document.getElementsByClassName('ql-toolbar')[0];
  //   if(inMobile) {
  //     toolbarHolder.style.display = 'none';
  //   }
  // },[inMobile])

  return (
    <>
      <video id='video' style={video} autoPlay></video>
      <TextAreaContainer
        id="text-area-container"
        className="text-area-container"
        onClick={textAreaClick}
        onBlur={textAreaBlur}
      >
        <StyledReactQuill>
          {modalOpen && (
            <Modal
              onClose={handleModalClose}
              onSearch={handleSearch}
              onPrev={handlePrev}
              onNext={handleNext}
            />
          )}
          {showPlaceholer && <PlaceHolder />}
          <ReactQuill
            // className="quill-editor"
            style={darkMode ? { color: ' white' } : { color: ' black' }}
            readOnly={prompterOn ? true : false}
            onKeyDown={handleKeyDown}
            // placeholder={placeholder}
            ref={reactQuillRef}
            onChange={(value) => {
              handleQuillChange(value);
            }}
            formats={[
              'bold',
              'italic',
              'underline',
              'list',
              'bullet',
              'color',
              'background',
            ]}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [
                  {
                    color: [
                      '#000000',
                      '#e60000',
                      '#ff9900',
                      '#ffff00',
                      '#008a00',
                      '#0066cc',
                      '#9933ff',
                      '#ffffff',
                      '#facccc',
                      '#ffebcc',
                      '#ffffcc',
                      '#cce8cc',
                      '#cce0f5',
                      '#ebd6ff',
                      '#bbbbbb',
                      '#f06666',
                      '#ffc266',
                      '#ffff66',
                      '#66b966',
                      '#66a3e0',
                      '#c285ff',
                      '#888888',
                      '#a10000',
                      '#b26b00',
                      '#b2b200',
                      '#006100',
                      '#0047b2',
                      '#6b24b2',
                      '#444444',
                      '#5c0000',
                      '#663d00',
                      '#666600',
                      '#003700',
                      '#002966',
                      '#3d1466',
                      '',
                    ],
                  },
                  {
                    background: [
                      '#000000',
                      '#e60000',
                      '#ff9900',
                      '#ffff00',
                      '#008a00',
                      '#0066cc',
                      '#9933ff',
                      '#ffffff',
                      '#facccc',
                      '#ffebcc',
                      '#ffffcc',
                      '#cce8cc',
                      '#cce0f5',
                      '#ebd6ff',
                      '#bbbbbb',
                      '#f06666',
                      '#ffc266',
                      '#ffff66',
                      '#66b966',
                      '#66a3e0',
                      '#c285ff',
                      '#888888',
                      '#a10000',
                      '#b26b00',
                      '#b2b200',
                      '#006100',
                      '#0047b2',
                      '#6b24b2',
                      '#444444',
                      '#5c0000',
                      '#663d00',
                      '#666600',
                      '#003700',
                      '#002966',
                      '#3d1466',
                      '',
                    ],
                  },
                ],
              ],
            }}
            value={editorValue}
          />
          {/* { hoveredButton && (
            <Tooltip
              title="RichText Formatting"
              color="#273d57"
              visible={showTooltip}
            >
              <div
                style={{
                  position: 'relative',
                  left: '155px',
                  right: '0',
                  top: '40px',
                }}
              />
            </Tooltip>
          )} */}
        </StyledReactQuill>
      </TextAreaContainer>
    </>
  );
};

export default ScriptWindows;

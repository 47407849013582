  import React, { useEffect } from 'react';
  import {
    BrowserRouter as Router,
    Route,
    Routes,
    useParams,
  } from 'react-router-dom';
  import LicenseKey from './License/LicenseKey';
  import Prompter from './scriptWindow/prompter';
  import { useDispatch, useSelector } from 'react-redux';
  import { isMobile } from 'react-device-detect';
  import ToolBar from './toolbar/toolbar';
  import { setScrollSpeedAction } from './redux/actions/scrollSpeedActions';
  import { setPrompterFontSizeAction } from './redux/actions/prompterFontSizeActions';
  import { licenseConfigurationGET } from './api/api';
  import { setLicenseKeyAction } from './redux/actions/licenseKeyActions';
  import { setIsInTeamsAction } from './redux/actions/isInTeamsActions';
  import { setIsInMobileAction } from './redux/actions/inMobileActions';
  import authenticate from './service/auth.service';
  function App() {
    const prompterText = useSelector((state) => state.prompterText);
    const prompterFontSize = useSelector((state) => state.prompterFontSize);
    const scrollSpeed = useSelector((state) => state.scrollSpeed);
    const fileTitle = useSelector((state) => state.fileTitle);
    const fileText = useSelector((state) => state.fileText);
    const licenseKey = useSelector((state) => state.licenseKey);
    const darkMode = useSelector((state) => state.darkMode);
    const inTeams = useSelector((state) => state.inTeams);
    const inMobile = useSelector((state) => state.inMobile);
    const dispatch = useDispatch();

    useEffect(() => {
      //  Updates local storage everytime a setting is changed.
      window.localStorage.setItem(
        'settings',
        JSON.stringify({
          fontSize: prompterFontSize,
          scrollSpeed: scrollSpeed,
          prompterText: prompterText,
          fileTitle: fileTitle,
          fileText: fileText,
          licenseKey: licenseKey,
          darkMode: darkMode,
        })
      );
    }, [
      prompterFontSize,
      scrollSpeed,
      prompterText,
      fileTitle,
      fileText,
      licenseKey,
      darkMode,
    ]);

    function inIframe () {
      try {
          return window.self !== window.top;
      } catch (e) {
          return true;
      }
    }

      //Check if App is opened in browser or MS
    useEffect(() => {
        inIframe();
        console.log(inIframe());
        console.log('Start Check in Teams: ',inTeams)
      if(inIframe()) {
        dispatch(setIsInTeamsAction(true));
        console.log('App in Teams - in Teams: ', inTeams);
      }
      else {
        dispatch(setIsInTeamsAction(false));
        console.log('App in Teams - in Browser: ', inTeams);
      }
    },[inTeams])
    

    useEffect(() => {
      const testInMobile = () => {
        if(isMobile) {
          dispatch(setIsInMobileAction(true));
          console.log('NASA PHONE',inMobile);
        }
        else {
          dispatch(setIsInMobileAction(false));
          console.log("WALA SA PHONE",inMobile)
        }
      }
      testInMobile();

    },[inMobile]);

    
    useEffect(() => {
      const openResizedWindow = () => {
        const width = 650; // Specify your desired width
        const height = 350; // Specify your desired height

        if (!window.opener || window.opener.closed) {
          if (!localStorage.getItem('windowOpened')) {
            const screenWidth = window.screen.availWidth;
            const screenHeight = window.screen.availHeight;

            const screenX = (screenWidth - width) / 2;
            const screenY = (screenHeight - height) / 2 - height / 2;

            const newWindow = window.open(
              window.location.href,
              '_blank',
              `width=${width},height=${height},screenX=${screenX},screenY=${screenY}`
            );
            // Close the current window

            // Set flag in local storage to indicate the window has been opened
            localStorage.setItem('windowOpened', true);

            // Prevent multiple pop-ups

            if (newWindow) {
              setTimeout(() => {
                newWindow.addEventListener('beforeunload', () => {
                  localStorage.removeItem('windowOpened');
                });
                newWindow.focus();
              }, 1000);
            }
            window.parent.close();
          } else {
            // Reset the flag if it was already set
            localStorage.removeItem('windowOpened');
          }
        }
      };
      openResizedWindow();
    }, []);
    useEffect(() => {
      // Function to update the document title
      const updateDocumentTitle = () => {
        const title = '  VODIUM | Your Virtual Teleprompter'; // Replace this with your desired title
        document.title = title;
      };

      // Call the function to update the document title when the component mounts
      updateDocumentTitle();

      // Clean up the title change when the component unmounts
      return () => {
        document.title = 'VODIUM | Your Virtual Teleprompter'; // Reset the title when the component unmounts (optional)
      };
    }, []);

    useEffect(() => {
      const handleDocumentKeyDown = (event) => {
        if (event.ctrlKey && event.key === 's') {
          event.preventDefault(); // Prevent the default browser behavior
        }
        if (event.ctrlKey && event.key === 'o') {
          event.preventDefault(); // Prevent the default browser behavior
        }
      };

      document.addEventListener('keydown', handleDocumentKeyDown);

    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/license"
          element={
            <>
              <button href="/">Click Me</button>
              <LicenseKey />
            </>
          }
        />
        <Route
          exact
          path="/:token"
          element={
            <>
              <ExtractKey />
              <Prompter />
              <ToolBar />
            </>
          }
        />
        <Route
          exact
          path=""
          element={
            <>
              <ExtractKey />
              <Prompter />
              <ToolBar />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

function ExtractKey() {
  const dispatch = useDispatch();
  const licenseKey = useSelector((state) => state.licenseKey);
  const params = useParams();
  const { token } = params;
  console.log('ExtractKey Token', token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokens = token === undefined ? null : token;
        console.log('Token', token);
        if (tokens !== null) {
          const data = await authenticate(tokens);
          console.log('Email-Data', data);
          dispatch(setLicenseKeyAction(data));
          setTimeout(() => {
            licenseConfigurationGET(licenseKey).then((result) => {
              if (result && result.settings) {
                const { settings } = result;
                const parseSettings = JSON.parse(settings);
                let { SCROLL_SPEED, FONT_SIZE } = parseSettings;
                const scroll = JSON.stringify(SCROLL_SPEED);
                const font = JSON.stringify(FONT_SIZE);
                console.log(scroll);
                console.log(font);
                dispatch(setScrollSpeedAction(SCROLL_SPEED));
                dispatch(setPrompterFontSizeAction(FONT_SIZE));
              } else {
                console.log('Error: Invalid result or missing settings');
              }
            });
          }, 1000);
        } else {
          console.log('Email null',);
          await authenticate(tokens);
        }
      } catch (error) {
        console.log('Error', error);
      }
    };
    fetchData();

  }, []);
  return null;
}

export default App;

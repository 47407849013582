import styled from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';
import path from 'path';
import { useSelector, useDispatch } from 'react-redux';
import { setFileTitleAction } from '../redux/actions/fileTitleAction';
import { setPrompterTextAction } from '../redux/actions/prompterTextActions';
import { setPrompterOnAction } from '../redux/actions/prompterOnActions';
import { setSettingsOpenAction } from '../redux/actions/settingsOpenActions';
import { setFileTextAction } from '../redux/actions/setFileTextAction';
const mammothPlus = require('mammoth-plus');

// CSS
var file = '';
const FileManagerDiv = styled.div`
  display: inline-flex;
  font-size: 15px;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  margin-top: 5px;
  position: relative;
  margin-left: -20px;
  color: white;
`;
const FileName = styled.div`
  margin-left: 30px;
  margin-top: -27px;
  color: white;
  position: fixed;
`;


export default function FileManager({ textScript, findScript }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showQuitModal, setShowQuitModal] = useState(false);
  const modalRef = useRef(null);
  const fileTitle = useSelector((state) => state.fileTitle);
  const dispatch = useDispatch();
  const prompterText = useSelector((state) => state.prompterText);
  const settingsOpen = useSelector((state) => state.settingsOpen);
  const inTeams = useSelector((state) => state.inTeams);
  // eslint-disable-next-line no-unused-vars
  const toggleMenu = (event) => {
    event.stopPropagation();
    setTimeout(() => {
      setShowMenu(!showMenu);
    }, 0);
  };
  const handleFileTitle = (title) => {
    dispatch(setFileTitleAction(title));
    console.log('FILE TITLE', title);
  };
  const handleMenuItemClick = (menuItem) => {
    // Handle the menu item click based on the selected menuItem
    console.log('Clicked:', menuItem.label);
    if (menuItem.label === 'Close') {
      setShowMenu(false);
    } else if (menuItem.label === 'Help') {
      window.open('https://vodium.com/support', '_blank');
    } else if (menuItem.label === 'Quit') {
      setShowQuitModal(true);
    } else if (menuItem.label === 'Open') {
      setShowMenu(false);

      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.vo,.txt,.docx';

      fileInput.onchange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            const fileData = fileReader.result;
            const fileName = selectedFile.name;
            file = fileName;
            handleFileTitle(fileName);
            console.log('BaseName or Title of file:', fileName);
            // Do something with the selectedFile

            if (path.extname(fileName) === '.docx') {
              // Handle .docx file
              mammothPlus
                .convertToHtml({ arrayBuffer: fileData }) // Pass the file data as 'arrayBuffer'
                .then((result) => {
                  const html = result.value;
                  const startTag = html.replaceAll("<font","<span");
                  const endTag = startTag.replaceAll("</font", "</span")
                  textScript(endTag);
                })
                .catch((error) => {
                  console.log(error.message);
                });
            } else if (path.extname(fileName) === '.vo') {
              const reader = new FileReader();

              reader.onload = () => {
                const data = reader.result;
                textScript(data);
              };

              reader.onerror = (event) => {
                console.error('Error reading file:', event.target.error);
              };

              reader.readAsText(selectedFile, 'utf-8');
            } else if (path.extname(fileName) === '.pdf') {
            } else {
              const reader = new FileReader();

              reader.onload = () => {
                const data = reader.result;
                textScript(data);
              };

              reader.onerror = (event) => {
                console.error('Error reading file:', event.target.error);
              };

              reader.readAsText(selectedFile, 'utf-8');
            }
          };

          fileReader.readAsArrayBuffer(selectedFile); 
        }
      };

      fileInput.click();
    } else if (menuItem.label === 'New') {
      createNewFile();
      setShowMenu(false);
    } else if (menuItem.label === 'Download') {
      console.log('PAYL', file);
      setShowMenu(false);
      Download(file);
    } else if (menuItem.label === 'Find') {
      findScript(true);
      setShowMenu(false);
       dispatch(setPrompterOnAction(false));
       dispatch(setSettingsOpenAction(!settingsOpen));
    }
  };

  const Download = async () => {
    try {
      const opts = {
        types: [
          {
            description: 'VO Files',
            accept: {
              'application/vo': ['.vo'],
            },
          },
        ],
      };

      const fileHandle = await window.showSaveFilePicker(opts);

      // Perform operations with the file handle
      console.log('File handle:', fileHandle);

      // Write data to the file using the file handle
      const writable = await fileHandle.createWritable();
      await writable.write(prompterText);
      await writable.close();

      const fileName = fileHandle.name;
      dispatch(setFileTitleAction(fileName));
      file = fileName;
      console.log('File saved successfully!');
      dispatch(setFileTextAction(prompterText));
    } catch (error) {
      // Handle errors or user cancellation
      console.error('Error saving file:', error);
    }
  };
  const createNewFile = () => {
    dispatch(setFileTitleAction(''));
    dispatch(setPrompterTextAction(''));
    console.log('clear prompter');
    console.log('clear file name');
  };
  // eslint-disable-next-line no-unused-vars
  const getDefaultStorage = () => {
    const defaultDirPath = 'Documents/VODIUM'; // Specify your desired default directory path

    // Check if the storage location exists in Local Storage
    let storageLocation = localStorage.getItem('storageLocation');
    if (!storageLocation) {
      // Storage location doesn't exist, create it
      storageLocation = defaultDirPath;
      console.log('Directory created:', storageLocation);
      localStorage.setItem('storageLocation', storageLocation);
    } else {
      // Storage location already exists
      console.log('Directory exists:', storageLocation);
    }

    return storageLocation;
  };

  const handleQuitConfirm = () => {
    window.onbeforeunload = null; // Clear the onbeforeunload event handler
    window.close(); // Close the web app
  };

  const handleQuitCancel = () => {
    setShowQuitModal(false); // Hide the quit confirmation modal
  };
  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const getAcceleratorLabel = (accelerator) => {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes('win')) {
      return accelerator.replace('CmdOrCtrl', 'Ctrl');
    } else if (platform.includes('mac')) {
      return accelerator.replace('CmdOrCtrl', 'Cmd');
    }
    return accelerator;
  };

  const menuItems = [
    { label: 'New', role: 'new', accelerator: 'New File ' },
    { label: 'Open', role: 'open', accelerator: 'Open File' },
    { label: 'Download', role: 'Download', accelerator: 'Download File' },
    { type: 'separator' },
    { label: 'Find', accelerator: 'Find Text' },
    { label: 'Help', role: 'help', accelerator: 'VODIUM Site' },
    { label: 'Quit', role: 'quit', accelerator: 'Quit App' },
  ];

  return (
    <div className="fileManager">
      <div>
        <FileManagerDiv>
          <FileName>
            <span>{fileTitle}</span>
          </FileName>
        </FileManagerDiv>
      </div>
      <div
        className="menu-popup"
        ref={modalRef}
        onClick={(event) => event.stopPropagation()}
      >
        <div className="menu-popup-content">
          {menuItems.filter(menu => !inTeams || (inTeams && ["Download","Quit"].indexOf(menu.label)===-1)).map((menuItem, index) => (
            <div
              key={index}
              className={`menu-item ${
                menuItem.type === 'separator' ? 'separator' : ''
              }`}
              onClick={() => handleMenuItemClick(menuItem)}
            >
              <span className="menu-item-label">{menuItem.label}</span>
              {menuItem.accelerator && (
                <span className="menu-item-accelerator">
                  {getAcceleratorLabel(menuItem.accelerator)}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>

      {showQuitModal && (
        <div className="quit-modal">
          <div className="quit-modal-content">
            <div className="quit-modal-message">
              Are you sure you want to quit?
            </div>
            <div className="quit-modal-buttons">
              <button
                className="quit-modal-confirm"
                onClick={handleQuitConfirm}
              >
                Confirm
              </button>
              <button className="quit-modal-cancel" onClick={handleQuitCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import {
  VODIUM_SIGN_IN,
  VODIUM_AUTH_WEB_TOKEN,
  VODIUM_WEB_APP,
} from '../firebase-config';
import { userAuthentication } from '../api/api';
export const refreshPage = () => {
  window.location.href = VODIUM_WEB_APP;
};

//Returns the user to the sign in page if accessing the launch page without logging in
export const redirectSignIn = () => {
  localStorage.removeItem(VODIUM_AUTH_WEB_TOKEN);
  window.location.href = VODIUM_SIGN_IN; //Comment this out when running the app locally
};


const authenticate = async (idToken) => {
  console.log('token', idToken);
  // const tokenss = localStorage.getItem('VODIUM_AUTH_WEB_TOKEN');
  try {
    if (idToken) {
      const email = await verifyUser(idToken);
      console.log('Authenticate Email', email);
      refreshPage();
      return email;
    } else {
      const token = localStorage.getItem(VODIUM_AUTH_WEB_TOKEN);
      console.log('Authenticate Token', token);
      if (token) {
        await verifyUser(token);
        console.log('VERIFY TOKEN', token);
      } else {
        console.log('authenticate else');
        redirectSignIn();
      }
    }
  } catch (error) {
    console.log('Authenticate error :', error);
  }
};

const verifyUser = async (token) => {
  console.log('Verify User Token', token);
  try {
    const result = await userAuthentication(token);
    console.log('RESULT VERIFYUSER', result);
    window.localStorage.setItem(VODIUM_AUTH_WEB_TOKEN, token);
    console.log('Verify Token Result', result);
    return result;
  } catch (error) {
    redirectSignIn();
    console.log('verify catch');
    throw error;
  }
};

export default authenticate;
